var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-2"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.modelTable.content,"search":_vm.search,"fixed-header":"","height":"auto","options":_vm.options,"server-items-length":_vm.modelTable.totalElements,"page":_vm.page,"footer-props":{
      'items-per-page-text': _vm.$t('table.item_table'),
      'items-per-page-all-text': _vm.$t('table.all'),
      'items-per-page-options': [15, 30, 50, 100],
    },"loading-text":_vm.$t('table.loading'),"no-data-text":_vm.$t('table.no_results_available'),"no-results-text":_vm.$t('table.no_results_search'),"dense":""},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"row justify-end p-2 pb-0"},[_c('div',{staticClass:"col-sm-12 pb-0 col-md-6 col-lg-3 col-xl-3"},[_c('AllBranchOfficeComponentClear',{ref:"branchOfficeDestination",attrs:{"branchLabel":"Sucursal origen","branchOfficeList":_vm.branchOfficeList,"isDisabled":_vm.isDisabled},on:{"branchChange":_vm.branchChange}})],1),_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-3 col-xl-3"},[_c('v-autocomplete',{staticClass:"br",attrs:{"items":_vm.listUserFilter,"label":!_vm.pagesAtributes.sortFilters.branchOfficeSelectOriginFilter
                ? 'Elige una sucursal origen'
                : 'Generado por',"data-vv-name":"select","clearable":"","disabled":!_vm.pagesAtributes.sortFilters.branchOfficeSelectOriginFilter},on:{"change":function($event){return _vm.userFilterChange(true)}},model:{value:(_vm.userFilter),callback:function ($$v) {_vm.userFilter=$$v},expression:"userFilter"}})],1),_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-3 col-xl-3"},[_c('v-select',{staticClass:"br",attrs:{"items":_vm.medicalPrecription ? _vm.statusFilterMedicalList : _vm.statusFilterList,"clearable":"","label":"Estatus","data-vv-name":"select"},on:{"change":function($event){return _vm.statusFilterChange()}},model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}})],1),_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-3 col-xl-3"},[_c('v-text-field',{staticClass:"br",attrs:{"suffix":_vm.search == '' ? '' : 'enter',"disabled":_vm.loading_search,"label":_vm.$t('table.search')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.searchValue.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',{staticClass:"pt-2"},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.search != ''),expression:"search != ''"}],staticClass:"feather icon-corner-down-left"})])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]},proxy:true},{key:`item.actions`,fn:function({ item }){return [(!_vm.medicalPrecription)?_c('div',[(
            (_vm.type == 1 && _vm.shipmentsCancel) ||
            (_vm.type == 0 && _vm.shipmentsCancelDispensing)
          )?_c('div',[(item.statusShipment != 7 && item.statusShipment != 6)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"hand",on:{"click":function($event){return _vm.cancellation(item)}}},'span',attrs,false),on),[_c('i',{staticClass:"fa fa-ban text-danger ml-5"})])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("table.cancel"))+" ")])]):_vm._e(),(item.statusShipment == 7 || item.statusShipment == 6)?_c('span',[_c('i',{staticClass:"fa fa-ban ml-5",staticStyle:{"color":"#e2e2e2"}})]):_vm._e(),(item.statusShipment == 7 || item.statusShipment == 6)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"#E2E2E2"}},[_vm._v(" mdi mdi-ban ")]):_vm._e()],1):_vm._e()]):_c('div',[(
            (_vm.type == 1 && _vm.shipmentsCancel) ||
            (_vm.type == 0 && _vm.shipmentsCancelDispensing)
          )?_c('div',[(
              item.statusPrescription != 7 && item.statusPrescription != 3
            )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"hand",on:{"click":function($event){return _vm.cancellation(item)}}},'span',attrs,false),on),[_c('i',{staticClass:"fa fa-ban text-danger ml-5"})])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("table.cancel"))+" ")])]):_vm._e(),(
              item.statusPrescription == 7 || item.statusPrescription == 3
            )?_c('span',[_c('i',{staticClass:"fa fa-ban ml-5",staticStyle:{"color":"#e2e2e2"}})]):_vm._e(),(
              item.statusPrescription == 7 || item.statusPrescription == 3
            )?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"#E2E2E2"}},[_vm._v(" mdi mdi-ban ")]):_vm._e()],1):_vm._e()])]}},{key:`item.statusShipment`,fn:function({ item }){return [_c('ChipTableCommonPagination',{attrs:{"status":item.statusShipment,"medicalPrecription":_vm.medicalPrecription}})]}},{key:`item.statusPrescription`,fn:function({ item }){return [_c('ChipTableCommonPagination',{attrs:{"status":item.statusPrescription,"medicalPrecription":_vm.medicalPrecription}})]}},{key:`item.prescriptionType`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.prescriptionType(item.prescriptionType))+" ")]}},{key:`item.status`,fn:function({ item }){return [(item.status == 1)?_c('v-chip',{staticClass:"m-1",attrs:{"color":"green","dark":""}},[_vm._v(" Activo ")]):_vm._e(),(item.status == 3)?_c('v-chip',{staticClass:"m-1",attrs:{"color":"grey","dark":""}},[_vm._v(" Producto no conforme ")]):_vm._e(),(item.status == 4)?_c('v-chip',{staticClass:"m-1",attrs:{"color":"red","dark":""}},[_vm._v(" Cancelado ")]):_vm._e()]}}],null,true)}),_c('ButtonPrintComponent')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }